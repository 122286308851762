import {ACTIONS} from '../actions/actionKeys';

export const initialState = {
	ESBData: {
		companyCode: '',
		outletCode: '',
		transId: '',
	},
	tableNumber: null,
	orderData: {},
	billIsRated: {},
	openState: {
		closeTime: null,
		isOpen: true,
	},
	loadedAt: null,
	ayceData: {
		isAyceTable: false,
		orderEndTime: null,
		pax: null,
	},
};

export default function user(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_ESB_DATA:
		return {
			...state,
			ESBData: payload,
			loadedAt: Date.now(),
		};
	case ACTIONS.SET_TABLE_NUMBER:
		return {
			...state,
			tableNumber: payload,
		};
	case ACTIONS.SET_ORDER_DATA:
		return {
			...state,
			orderData: payload,
		};
	case ACTIONS.SET_BILL_IS_RATED:
		return {
			...state,
			billIsRated: payload,
		};
	case ACTIONS.SET_OPEN_STATE:
		return {
			...state,
			openState: payload,
		};
	case ACTIONS.SET_AYCE_DATA:
		return {
			...state,
			ayceData: payload,
		};
	case ACTIONS.CLEAR_AYCE_DATA:
		return {
			...state,
			ayceData: initialState.ayceData,
		};
	case ACTIONS.CLEAR_ESB_DATA:
		return initialState;
	default:
		return state;
	}
}
