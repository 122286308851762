import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import get from 'lodash/get';

import {version} from '../../package.json';
import {store} from 'store';

class BugsnagClass {
	constructor() {
		this.bugsnagEnabled =
			process.env.NODE_ENV !== 'test' &&
			process.env.REACT_APP_RELEASE_STAGE === 'production' &&
			process.env.REACT_APP_BUGSNAG_APIKEY;
		this.initialize();
	}
	setBugsnagObjectMetadata(report, errObj, keyName) {
		Object.keys(errObj).forEach(key => {
			report.addMetadata(keyName, key, errObj[key]);
		});
	}
	initialize() {
		if (this.bugsnagEnabled) {
			this.Bugsnag = Bugsnag.start({
				apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
				autoBreadcrumbs: true,
				plugins: [new BugsnagPluginReact()],
				releaseStage: process.env.REACT_APP_RELEASE_STAGE,
				appVersion: version,
				onError: async event => {
					const {ESBData, tableNumber, orderData} =
						store.getState().order || {};

					tableNumber && event.addMetadata('order', 'tableName', tableNumber);
					ESBData?.companyCode &&
						event.addMetadata('order', 'companyCode', ESBData.companyCode);
					ESBData?.outletCode &&
						event.addMetadata('order', 'outletCode', ESBData.outletCode);
					ESBData?.transId &&
						event.addMetadata('order', 'transId', ESBData.transId);
					orderData?.salesNum &&
						event.addMetadata('order', 'salesNum', orderData.salesNum);
				},
			});
		}
	}

	error(err, severity = 'warning') {
		if (this.Bugsnag) {
			if (get(err, 'message') === 'Network Error') {
				severity = 'warning';
			}
			Bugsnag.notify(err, report => {
				if (err?.config) {
					const {headers, method, params, data, url} = err.config;
					report.context =
						err.message === 'Network Error'
							? `Network Error (${url})`
							: `Request failed with status code ${err?.response?.status} (${url})`;
					this.setBugsnagObjectMetadata(
						report,
						{
							headers,
							params,
							requestData: data,
							responseData: err?.response?.data,
							status: err?.response?.status,
							method,
							url,
						},
						'metadata',
					);
				} else if (err.graphql) {
					report.context = `GQL${
						err.graphql?.path ? ` | Path: ${err.graphql.path.join(', ')}` : ''
					}${
						err.graphql?.extensions?.code
							? ` | Code: ${err.graphql.extensions.code}`
							: ''
					}`;
					this.setBugsnagObjectMetadata(report, err.graphql, 'metadata');
				} else {
					this.setBugsnagObjectMetadata(report, {metadata: JSON.stringify(err)}, 'metadata');
				}
				// ["info", "warning", "error"]
				report.severity = severity;

				err.customContext && (report.context = err.customContext);
			});
		}
	}
}

// const setBugsnagObjectMetadata = (report, errObj) => {
//   Object.keys(errObj).forEach((key) => {
//     report.addMetadata('metadata', key, errObj[key]);
//   });
// };

export default new BugsnagClass();
