import {useEffect} from 'react';

export const useDisableBodyScroll = (enable = true) => {
	useEffect(() => {
		enable && (document.body.style.overflow = 'hidden');
		return () => {
			enable && (document.body.style.overflow = '');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enable]);
};
