import {combineReducers} from 'redux';
import order from './order';
import cart from './cart';
import menu from './menu';
import promo from './promo';
export default combineReducers({
	order,
	cart,
	menu,
	promo,
});
