import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';

import crashLogger from 'utils/logger';

import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';

const ErrorBoundary = crashLogger.bugsnagEnabled
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
