import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createTransform from 'redux-persist/es/createTransform';

import rootReducer from './reducers';
import {initialState} from './reducers/order';

const EXPIRY_DURATION = 12 * 60 * 60 * 1000; // 12 hours

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['order', 'cart', 'menu', 'promo'],
	transforms: [
		createTransform(
			inboundState => {
				return inboundState;
			},
			outboundState => {
				// clear redux persist store and force reload page if expired
				// the store is marked expired when it's last ESB loadedAt is older than EXPIRY_DURATION
				if (
					outboundState.loadedAt !== null &&
          outboundState.loadedAt + EXPIRY_DURATION < Date.now()
				) {
					localStorage.removeItem('persist:root');
					window.location.reload();
					return initialState;
				}
				return outboundState;
			},
			{whitelist: ['order']}, // define which reducers this transform gets called for.
		),
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
	// middlewares.unshift(loggerMiddleware);
}

const middlewareEnhancer = applyMiddleware(...middlewares);

const composer =
  process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;

const enhancers = [middlewareEnhancer];
const composedEnhancers = composer(...enhancers);

export const store = createStore(persistedReducer, composedEnhancers);
export const persistor = persistStore(store);
