import React, {lazy, Suspense, useEffect} from 'react';
import {Router, Route, Redirect, Switch} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

// Redux
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from 'store';

// Hooks
import {useDisableBodyScroll} from 'hooks/useDisableBodyScroll';

// Global components
import Toast from 'components/Toast';
import FlexedContainerLoading from 'components/Display/FlexedContainerLoading';
import BottomTabBar from 'components/BottomTabBar';

// Global utils
import {isIos} from 'utils/utils';
import history from 'utils/history';
import crashLogger from 'utils/logger';
import analytics from 'utils/analytics';

import './App.scss';

const importRetry = async (importFn, retries = 2, interval = 1000) => {
	try {
		return await importFn();
	} catch (error) {
		if (retries) {
			await new Promise(resolve => setTimeout(resolve, interval));
			return importRetry(importFn, retries - 1, interval);
		} else {
			crashLogger.error(error);
		}
	}
};

// Delay at least 200ms
const delayedImport = importFn => {
	return Promise.all([
		importRetry(importFn),
		new Promise(resolve => setTimeout(resolve, 200)),
	]).then(([moduleExports]) => moduleExports);
};

const BannerChecker = lazy(() => delayedImport(() => import('bannerCheck')));
const Home = lazy(() => importRetry(() => import('views/Home')));
const Menu = lazy(() => delayedImport(() => import('views/Menu')));
const MenuBrowse = lazy(() => delayedImport(() => import('views/MenuBrowse')));
const SubmenuPick = lazy(() =>
	delayedImport(() => import('views/SubmenuPick')),
);
const Cart = lazy(() => delayedImport(() => import('views/Cart')));
const Bill = lazy(() => delayedImport(() => import('views/Bill')));
const ClosedBill = lazy(() => delayedImport(() => import('views/ClosedBill')));
const Promo = lazy(() => delayedImport(() => import('views/Promo')));
const ErrorPage = lazy(() => importRetry(() => import('views/ErrorPage')));
const TableTracker = lazy(() => importRetry(() => import('views/TableTracker')));

smoothscroll.polyfill();

const ROUTES = [
	{path: '/:companyCode/:outletCode/:transId/order', component: Home},
	{path: '/banner-check', component: BannerChecker},
	{path: '/menu', component: Menu},
	{path: '/menu/browse', component: MenuBrowse},
	{path: '/menu/:menuId', component: SubmenuPick},
	{path: '/cart', component: Cart},
	{path: '/bill', component: Bill},
	{path: '/receipt', component: ClosedBill},
	{path: '/promo', component: Promo},
	{path: '/invalid', component: ErrorPage, props: {type: 'invalid'}},
	{path: '/error', component: ErrorPage, props: {type: 'error'}},
	{path: '/ayce-table-tracker', component: TableTracker},
];

function App() {
	useDisableBodyScroll(isIos());

	useEffect(() => {
		analytics.initialize();
		analytics.pageview();
		history.listen(() => {
			analytics.pageview();
		});
		// normalize url back to non modal path
		if (window.location.pathname.includes('/modal')) {
			history.goBack();
		}
	}, []);

	const renderRoutes = [];
	ROUTES.forEach(({path, component: Component, props}, i) => {
		renderRoutes.push(
			<Route
				key={i}
				exact
				path={`${path}/modal`}>
				<Component {...props} />
			</Route>,
		);
		renderRoutes.push(
			<Route
				key={i}
				exact
				path={path}>
				<Component {...props} />
			</Route>,
		);
	});

	return (
		<Provider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}>
				<div className="App">
					<Toast />
					<Suspense fallback={<FlexedContainerLoading />}>
						<Router history={history}>
							<Switch>
								{renderRoutes}
								<Route
									exact
									path="/">
									<Redirect to="/menu" />
								</Route>
								<Route path="*">
									<Redirect to="/invalid" />
								</Route>
							</Switch>
						</Router>
					</Suspense>
					<BottomTabBar />
				</div>
			</PersistGate>
		</Provider>
	);
}

export default App;
