import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';

import {faUserAstronaut} from '@fortawesome/pro-light-svg-icons';

import {ReactComponent as SVGBill} from 'assets/icons/bill.svg';
import {ReactComponent as SVGCart} from 'assets/icons/cart.svg';
import {ReactComponent as SVGMenu} from 'assets/icons/menu.svg';
import {ReactComponent as SVGPromo} from 'assets/icons/promo.svg';
// import {ReactComponent as SVGWaiter} from 'assets/icons/waiter.svg';

import localization from 'localization';
import history from 'utils/history';
import analytics from 'utils/analytics';
import {callWaiterRequest} from 'utils/request/order';

import {toast} from 'components/Toast';
import ConfirmationModal from 'components/Modal/ConfirmationModal';

import './style.scss';

function BottomTabBar() {
	const cartItems = useSelector(state => state.cart.items);
	const [activePath, setActivePath] = useState(window.location.pathname);
	const [modalVisible, setModalVisible] = useState(false);
	const [loadingCallWaiter, setLoadingCallWaiter] = useState(false);
	const TABS = [
		{icon: SVGMenu, label: 'MENU', path: '/menu'},
		{
			icon: SVGCart,
			label: 'CART',
			path: '/cart',
			counter: cartItems.reduce((a, b) => a + b.qty, 0),
		},
		{icon: SVGBill, label: 'ORDER', path: '/bill'},
		{icon: SVGPromo, label: 'PROMO', path: '/promo'},
		// {icon: SVGWaiter, label: 'WAITER', path: '/waiter'},
	];

	const callWaiter = async () => {
		analytics.logEvent('click_confirm_call_waiter');
		setLoadingCallWaiter(true);
		await callWaiterRequest();
		setLoadingCallWaiter(false);
		setModalVisible(false);
	};

	const onClickTab = (path, label) => {
		analytics.logEvent('click_nav_bar', {tab: label.toLowerCase()});
		toast.dismiss();
		if (path === '/waiter') {
			setModalVisible(true);
		} else {
			history.push(path);
		}
	};

	useEffect(() => {
		setActivePath(window.location.pathname);
		history.listen(location => {
			// may seem unnecessary, but we needed to force update state when tab is pressed in order for the UI to update
			setActivePath(location.pathname);
		});
	}, []);

	const shouldRender = TABS.some(
		e =>
			[e.path, e.path + '/modal', '/menu/browse', '/menu/browse/modal'].includes(activePath),
	);

	return (
		shouldRender && (
			<React.Fragment>
				<div className="BottomTabBar">
					{TABS.map((tab, i) => {
						const isActive = window.location.pathname.includes(tab.path);
						const TabIcon = tab.icon;
						return (
							<div
								key={i}
								className={clsx(
									'BottomTabBar__container',
									'touchable-opacity',
									isActive && 'BottomTabBar__container--active',
								)}
								onClick={() => onClickTab(tab.path, tab.label)}
							>
								<div
									className={clsx(
										'BottomTabBar__counter',
										!isActive &&
                      tab.counter > 0 &&
                      'BottomTabBar__counter--visible',
									)}
								>
									{tab.counter}
								</div>
								{TabIcon && <TabIcon />}
								<div>{tab.label}</div>
							</div>
						);
					})}
				</div>
				<ConfirmationModal
					icon={faUserAstronaut}
					title={localization.Global.CallWaiter.title}
					description={localization.Global.CallWaiter.description}
					visible={modalVisible}
					loading={loadingCallWaiter}
					disabled={loadingCallWaiter}
					primaryButtonText={localization.Global.CallWaiter.confirm}
					secondaryButtonText={localization.Global.CallWaiter.notNow}
					onClickPrimaryButton={callWaiter}
					onClickSecondaryButton={() => setModalVisible(false)}
					onBackdropPress={() => setModalVisible(false)}
					onClickClose={() => setModalVisible(false)}
					onModalOpen={() => analytics.logEvent('view_call_confirmation_popup')}
				/>
			</React.Fragment>
		)
	);
}

export default BottomTabBar;
