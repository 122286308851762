import Axios from 'axios';

import {store} from 'store';
import {
	clearAyceData,
	clearESBData,
	setAyceData,
	setOrderData,
	setTableNumber,
} from 'store/actions/order';

import localization from 'localization';

import analytics from 'utils/analytics';
import history from 'utils/history';
import crashLogger from 'utils/logger';
import {ERROR_CODES} from 'utils/constants';
import {redirectToReceiptIfClosed, setOutletOpenHours} from 'utils/utils';

import {toast} from 'components/Toast';

import {waitUntilMinimumDelay} from '.';

export const callWaiterRequest = async () => {
	try {
		const {transId, outletCode} = store.getState().order.ESBData;
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/callWaiter`,
			method: 'POST',
			data: {
				transId,
				outletCode,
			},
			timeout: 30000,
		});
		if (data.success) {
			toast.success(localization.Global.CallWaiter.callWaiterSuccess);
		} else {
			throw new Error('Call waiter failed');
		}
	} catch (error) {
		toast.error(localization.Global.CallWaiter.callWaiterFailed);
		crashLogger.error(error, 'error');
	}
};

export const getOrders = async () => {
	// save pathname in advance so the catch can still run if user manages to change page before the API can finish fetching.
	const pathname = window.location.pathname;
	try {
		const startTime = Date.now();
		const {transId, outletCode} = store.getState().order.ESBData;
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/order`,
			method: 'GET',
			params: {
				transId,
				outletCode,
			},
			timeout: 30000,
		});
		if (data.outlet?.openHours) {
			setOutletOpenHours(data.outlet.openHours);
		}
		if (data.order) {
			store.dispatch(setOrderData(data.order));
			store.dispatch(setTableNumber(data.order?.tableName));
			if (data.order?.isAyce) {
				store.dispatch(setAyceData({
					isAyceTable: true,
					orderEndTime: data.order?.ayceEndTime,
					// orderEndTime: 'December 07, 2022 23:50',
					pax: data.order?.aycePaxQty,
				}));
			} else {
				store.dispatch(clearAyceData());
			}

			analytics.setUserProperties();
			redirectToReceiptIfClosed();
			await waitUntilMinimumDelay(startTime);
			return data.order;
		}
	} catch (error) {
		if (
			[ERROR_CODES.INVALID_QR, ERROR_CODES.INVALID_TRANS_ID].includes(error?.response?.data?.errorCode) ||
      error?.response?.data?.statusCode === 404
		) {
			store.dispatch(clearESBData());
			history.replace('/invalid');
		} else {
			history.replace('/error', {pathname});
			crashLogger.error(error, 'error');
		}
	}
};

export const closeBill = async () => {
	try {
		const {transId, outletCode} = store.getState().order.ESBData;
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/closeBill`,
			method: 'POST',
			data: {
				transId,
				outletCode,
			},
			timeout: 30000,
		});
		if (data.success) {
			return true;
		}
	} catch (error) {
		crashLogger.error(error, 'error');
		return false;
	}
};

export const sendEmail = async (name, email) => {
	try {
		const {transId} = store.getState().order.ESBData;
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/emailReceipt`,
			method: 'POST',
			data: {
				transId,
				name,
				email,
			},
			timeout: 30000,
		});
		if (data.success) {
			return true;
		}
	} catch (error) {
		crashLogger.error(error, 'error');
		return false;
	}
};

export const sendRating = async (params = {}) => {
	try {
		const {transId} = store.getState().order.ESBData;
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/rating`,
			method: 'POST',
			data: {transId, ...params},
		});
		if (data.success) {
			return true;
		}
	} catch (error) {
		toast.error(localization.ClosedBill.ratingModal.rateFailed);
		crashLogger.error(error, 'error');
		return false;
	}
};

export const getAyceTables = async () => {
	try {
		const {data} = await Axios({
			url: `${process.env.REACT_APP_ORDER_SERVICE_API_HOST}/dinein/mc/order/ayce`,
			method: 'GET',
			timeout: 30000,
		});
		return data?.orders || [];
	} catch (error) {
		toast.error(localization.TableTracker.fetchError);
		crashLogger.error(error, 'error');
		return [];
	}
};
