import {ACTIONS} from './actionKeys';

export const setMenuCategories = payload => ({
	type: ACTIONS.SET_MENU_CATEGORIES,
	payload,
});

export const setActiveCategoryIndex = payload => ({
	type: ACTIONS.SET_ACTIVE_CATEGORY_INDEX,
	payload,
});

export const setLastMenuScrollTop = payload => ({
	type: ACTIONS.SET_LAST_MENU_SCROLL_TOP,
	payload,
});