import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {faCircle, faSquare} from '@fortawesome/pro-regular-svg-icons';
import {faCheckSquare, faDotCircle} from '@fortawesome/pro-solid-svg-icons';

import './style.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const RadioCheckboxBullet = ({active, type, color, size}) => {
	return (
		<div
			className="RadioCheckboxBullet"
			style={{width: size, height: size}}>
			<FontAwesomeIcon
				className="RadioCheckboxBullet__fill-container"
				icon={type === 'radio' ? faCircle : faSquare}
				style={{fontSize: size}}
				color={color}
			/>
			<FontAwesomeIcon
				className={clsx(
					'RadioCheckboxBullet__filler',
					active && 'RadioCheckboxBullet__filler--visible',
				)}
				icon={type === 'radio' ? faDotCircle : faCheckSquare}
				style={{fontSize: size}}
				color={color}
			/>
		</div>
	);
};

RadioCheckboxBullet.defaultProps = {
	active: false,
	type: 'radio',
	color: null,
	size: 16,
};

RadioCheckboxBullet.propTypes = {
	active: PropTypes.bool,
	type: PropTypes.oneOf(['radio', 'checkbox']),
	color: PropTypes.string,
	size: PropTypes.number,
};

export default RadioCheckboxBullet;
