export const ACTIONS = {
	SET_ESB_DATA: 'SET_ESB_DATA',
	CLEAR_ESB_DATA: 'CLEAR_ESB_DATA',
	SET_TABLE_NUMBER: 'SET_TABLE_NUMBER',
	SET_CART: 'SET_CART',
	CLEAR_CART: 'CLEAR_CART',
	SET_MENU_CATEGORIES: 'SET_MENU_CATEGORIES',
	SET_ACTIVE_CATEGORY_INDEX: 'SET_ACTIVE_CATEGORY_INDEX',
	SET_LAST_MENU_SCROLL_TOP: 'SET_LAST_MENU_SCROLL_TOP',
	SET_PROMO_BANNERS: 'SET_PROMO_BANNERS',
	SET_ORDER_DATA: 'SET_ORDER_DATA',
	SET_BILL_IS_RATED: 'SET_BILL_IS_RATED',
	SET_OPEN_STATE: 'SET_OPEN_STATE',
	SET_AYCE_DATA: 'SET_AYCE_DATA',
	CLEAR_AYCE_DATA: 'CLEAR_AYCE_DATA',
};
