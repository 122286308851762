import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import COLOR_PALLETES from 'assets/styles/_colors.module.scss';
import Spinner from 'components/Display/Spinner';

import './style.scss';

const Button = props => {
	// Methods
	const getColor = () => {
		if (props.mode === 'contained') {
			// Contained
			return {
				primary: COLOR_PALLETES.black,
				secondary: COLOR_PALLETES.secondary,
			};
		} else if (props.mode === 'outlined') {
			// Outlined
			return {
				primary: COLOR_PALLETES.secondary,
				secondary: 'transparent',
			};
		} else {
			return {
				primary: COLOR_PALLETES.secondary,
				secondary: 'transparent',
			};
		}
	};
	const getLabelStyle = () => {
		return {
			color: colors.primary,
			marginHorizontal: 4,
		};
	};
	const getButtonHeight = () => {
		switch (props.size) {
		case 'small':
			return 26;
		case 'large':
			return 48;
		case 'medium':
		default:
			return 34;
		}
	};
	const getBorderStyle = colors => {
		if (props.mode === 'outlined') {
			return {
				borderColor: colors.primary,
				borderWidth: 1,
				borderStyle: 'solid',
			};
		}
		return {borderWidth: 0};
	};
	const getMainStyle = (colors, buttonHeight, borderStyle, labelStyle) => {
		const arrStyle = isArray(props.style)
			? [
				{height: buttonHeight, backgroundColor: colors.secondary},
				borderStyle,
				labelStyle,
				...props.style,
			]
			: isObject(props.style)
				? [
					{height: buttonHeight, backgroundColor: colors.secondary},
					borderStyle,
					labelStyle,
					props.style,
				]
				: [];
		return arrStyle.reduce((previousValue, currentValue) => {
			return isObject(currentValue)
				? {...previousValue, ...currentValue}
				: previousValue;
		}, {});
	};
	// // Variables
	const colors = getColor();
	const buttonHeight = getButtonHeight();
	const borderStyle = getBorderStyle(colors);
	const labelStyle = getLabelStyle(colors);
	const mainStyle = getMainStyle(colors, buttonHeight, borderStyle, labelStyle);
	const style = {...mainStyle, ...(props.disabled && {opacity: 0.4})};
	const labelClassname =
    props.size !== 'large' ? 'Button--link-small' : 'Button--link-medium';
	// Render
	return (
		<button
			style={style}
			onClick={e => !props.disabled && props.onClick(e)}
			className={clsx(
				'Button',
				'touchable-opacity',
				'disable-text-select',
				labelClassname,
				props.className,
			)}>
			{props.loading ? (
				<Spinner
					type="spinner"
					size={16} />
			) : typeof props.label === 'string' ? (
				props.label
			) : (
				props.children
			)}
		</button>
	);
};

Button.defaultProps = {
	onClick: () => null,
	mode: 'contained',
	disabled: false,
	size: 'large',
	loading: false,
	style: {},
};

Button.propTypes = {
	className: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	mode: PropTypes.oneOf(['outlined', 'contained', 'ghost']),
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	label: PropTypes.string,
	children: PropTypes.any,
};

export default Button;
