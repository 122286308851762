import {ACTIONS} from '../actions/actionKeys';

const initialState = {
	banners: [],
};

export default function promo(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_PROMO_BANNERS:
		return {
			...state,
			banners: payload,
		};
	default:
		return state;
	}
}
