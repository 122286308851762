import {ACTIONS} from '../actions/actionKeys';

const initialState = {
	items: [],
};

export default function cart(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_CART:
		return {
			...state,
			items: payload,
		};
	case ACTIONS.CLEAR_CART:
		return initialState;
	default:
		return state;
	}
}
