import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import {store} from 'store';

import packageJson from '../../package.json';

class Analytics {
	constructor() {
		this.defaultProperties = {
			appVersion: packageJson.version,
			timestamp: `${new Date().getTime()}`,
		};

		this.TagManager = null;
		this.ReactGA = null;

		this.buildTrack = process.env.REACT_APP_RELEASE_STAGE;
		this.analyticsEnabled = process.env.NODE_ENV !== 'test' && this.buildTrack === 'production';
	}

	initialize(gtmIdCustom, gaIdCustom) {
		if (this.analyticsEnabled) {
			const gtmId = gtmIdCustom || process.env.REACT_APP_GTM_ID;
			if (gtmId) {
				TagManager.initialize({
					gtmId,
				});
				this.TagManager = TagManager;
			}

			const gaId = gaIdCustom || process.env.REACT_APP_GA4_ID;
			if (gaId) {
				ReactGA.initialize(gaId);
				this.ReactGA = ReactGA;
			}
			this.setUserProperties();
			this.trackPerformance();
		}
	}

	setUserProperties() {
		if (this.analyticsEnabled) {
			const {ESBData, tableNumber, orderData} = store.getState().order || {};
			tableNumber && (this.defaultProperties.tableName = tableNumber);
			ESBData?.companyCode &&
        (this.defaultProperties.companyCode = ESBData.companyCode);
			ESBData?.outletCode &&
        (this.defaultProperties.outletCode = ESBData.outletCode);
			ESBData?.transId && (this.defaultProperties.transId = ESBData.transId);
			orderData?.salesNum && (this.defaultProperties.salesNum = orderData.salesNum);
		}
	}

	logEvent(eventName, param = {}) {
		if (this.ReactGA && this.analyticsEnabled) {
			try {
				const eventParam = {...this.defaultProperties, ...param};
				this.ReactGA.event(eventName, eventParam);
			} catch (e) {
				console.log(e);
			}
		}
	}

	pageview() {
		if (this.ReactGA && this.analyticsEnabled) {
			this.ReactGA.send('pageview');
		}
	}

	trackPerformance() {
		try {
			const observer = new PerformanceObserver(list => {
				for (const entry of list.getEntries()) {
					this.logEvent('paint_performance', {
					  timingCategory: 'Paint metrics',
					  timingVar: entry.name,
					  timingValue: Math.round(entry.startTime),
					});
				}
			});
			observer.observe({
				entryTypes: ['paint'],
			});
		} catch (e) {
			// Do nothing if the browser doesn't support this API
		}
	}

}

const analytics = new Analytics();

export default analytics;

