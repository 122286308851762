import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-light-svg-icons';

import Button from 'components/Button';
import LargeCaption from 'components/Display/LargeCaption';

import history from 'utils/history';

import './style.scss';
import Modal from '../index.js';
import RadioCheckboxBullet from 'components/Display/RadioCheckboxBullet';

function ConfirmationModal(props) {
	const [isChecked, setIsChecked] = useState(false);
	const isButtonDisabled = props.disabled || (props.checkboxText && !isChecked);
	return (
		<Modal
			className="ConfirmationModal"
			visible={props.visible}
			onModalOpen={props.onModalOpen}
			onModalClosed={props.onModalClosed}
			onBackdropPress={() => !props.loading && props.onBackdropPress()}
			onClickBackButton={() => props.loading ? history.go(1) : props.onClickClose()}
			enableBackButtonHandling={props.enableBackButtonHandling}>
			<LargeCaption
				icon={props.icon}
				iconColor={props.iconColor}
				title={props.title}
				description={props.description}
				isSVG={props.isSVG}
			/>
			{props.checkboxText && (
				<div
					className="ConfirmationModal__checkbox"
					onClick={() => setIsChecked(!isChecked)}>
					<RadioCheckboxBullet
						active={isChecked}
						type="checkbox"
						size={14}
					/>
					<label
						className="ConfirmationModal__checkbox-label">
						{props.checkboxText}
					</label>
				</div>
			)}
			<div className="ConfirmationModal__container">
				<Button
					label={props.primaryButtonText}
					loading={props.loading}
					disabled={isButtonDisabled}
					onClick={() => !props.loading && props.onClickPrimaryButton()}
				/>
				{!props.singleButtonMode && (
					<Button
						mode="ghost"
						label={props.secondaryButtonText}
						onClick={() => !props.loading && props.onClickSecondaryButton()}
					/>
				)}
			</div>
			{props.showCloseButton && (
				<div
					className="ConfirmationModal__close touchable-opacity"
					onClick={() => !props.loading && props.onClickClose()}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			)}
		</Modal>
	);
}

ConfirmationModal.defaultProps = {
	visible: false,
	loading: false,
	disabled: false,
	showCloseButton: true,
	enableBackButtonHandling: true,
	singleButtonMode: false,
	primaryButtonText: 'Primary',
	secondaryButtonText: 'Secondary',
	onClickPrimaryButton: () => {},
	onClickSecondaryButton: () => {},
	onModalOpen: () => {},
	onModalClosed: () => {},
	onBackdropPress: () => {
		console.log('Modal Outer Clicked!');
	},
	onClickClose: () => {
		console.log('Close Button Clicked!');
	},
};
ConfirmationModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	enableBackButtonHandling: PropTypes.bool,
	singleButtonMode: PropTypes.bool,
	primaryButtonText: PropTypes.string.isRequired,
	secondaryButtonText: PropTypes.string,
	icon: PropTypes.object,
	iconColor: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	checkboxText: PropTypes.string,
	isSVG: PropTypes.bool,
	onClickPrimaryButton: PropTypes.func,
	onClickSecondaryButton: PropTypes.func,
	onModalOpen: PropTypes.func,
	onModalClosed: PropTypes.func,
	onBackdropPress: PropTypes.func,
	onClickClose: PropTypes.func,
	className: PropTypes.string,
};

export default ConfirmationModal;
