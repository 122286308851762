import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {isIos} from 'utils/utils';
import history from 'utils/history';

import './style.scss';

function Modal(props) {
	const isInitial = useRef(true);
	const originalBodyOverflow = document.body.style.overflow;
	const [showModal, setShowModal] = useState(false);
	const [showContent, setShowContent] = useState(false);

	const enableBackButtonHandling = props.enableBackButtonHandling && !isIos();

	useEffect(() => {
		// add is initial logic to prevent close modal function from firing
		if (isInitial.current && !props.visible) {
			isInitial.current = false;
			return;
		}
		if (props.visible) {
			document.body.style.overflow = 'hidden';
			setShowModal(true);
			setTimeout(() => {
				setShowContent(true);
				props.onModalOpen();
			}, 100);
			enableBackButtonHandling && history.push(history.location.pathname + '/modal');
		} else {
			if (window.location.pathname.includes('/modal')) {
				enableBackButtonHandling && history.goBack();
			}
			document.body.style.overflow = originalBodyOverflow;
			setShowContent(false);
			setTimeout(() => {
				setShowModal(false);
				props.onModalClosed();
			}, 400);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.visible]);

	useEffect(() => {
		let unlisten;
		if (enableBackButtonHandling) {
			unlisten = history.listen(({pathname}) => {
				if (showModal && !pathname.includes('/modal')) {
					props.onClickBackButton();
				}
			});
		}
		return () => unlisten && unlisten();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal, enableBackButtonHandling]);

	return (
		showModal && (
			<div className="Modal">
				<div
					className={clsx('Modal__overlay', showContent && 'Modal--open')}
					onClick={props.onBackdropPress}
				/>
				<div
					className={clsx(
						'Modal__content',
						props.className,
						showContent && 'Modal--open',
					)}
					onClick={e => {
						e.stopPropagation();
					}}
					style={{...props.style}}>
					<div className="Modal__content-inner">{props.children}</div>
				</div>
			</div>
		)
	);
}

Modal.defaultProps = {
	visible: false,
	enableBackButtonHandling: true,
	onModalOpen: () => null,
	onModalClosed: () => null,
	onClickBackButton: () => null,
	onBackdropPress: () => {
		console.log('Modal Outer clicked!');
	},
	className: '',
	style: {},
};
Modal.propTypes = {
	enableBackButtonHandling: PropTypes.bool,
	visible: PropTypes.bool.isRequired,
	onModalOpen: PropTypes.func,
	onModalClosed: PropTypes.func,
	onClickBackButton: PropTypes.func,
	onBackdropPress: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.any,
};

export default Modal;
