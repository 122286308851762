import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import './style.scss';

function FlexedContainerLoading(props) {
	return (
		<div
			className={clsx('FlexedContainerLoading', props.containerClassName)}
			style={props.containerStyle}>
			<Spinner {...props} />
		</div>
	);
}

FlexedContainerLoading.defaultProps = {
	type: 'planetary',
	size: 150,
};

FlexedContainerLoading.propTypes = {
	containerClassName: PropTypes.string,
	containerStyle: PropTypes.object,
};

export default FlexedContainerLoading;
