import React from 'react';
import clsx from 'clsx';
import {useToaster, toast as toastFunc} from 'react-hot-toast';

import localization from 'localization';

import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLightbulbOn} from '@fortawesome/pro-regular-svg-icons';

import './style.scss';

let currentToastId = null;

const dismiss = () => {
	// Clear toast by saving toast Id as dismissing all toast (by not providing Id) may cause the next toast to be closed immediately.
	// Potential suspect is that by dismissing all toast, the timeout does not reset.
	currentToastId && toastFunc.dismiss(currentToastId);
	currentToastId = null;
};

export const toast = {
	success: message => {
		dismiss();
		toastFunc.success(message);
	},
	error: message => {
		dismiss();
		toastFunc.error(message);
	},
	menuResponse: message => {
		dismiss();
		toastFunc(message, {
			type: 'menu-response',
			duration: 8000,
		});
	},
	quickTips: message => {
		dismiss();
		toastFunc(message, {
			type: 'quick-tips',
			duration: 8000,
		});
	},
	dismiss,
};

const Toast = () => {
	const {toasts, handlers} = useToaster({duration: 5000});
	const {startPause, endPause} = handlers;
	const singleToast = toasts[0];
	currentToastId = toasts[0]?.id;
	return singleToast ? (
		<div
			className={clsx('Toast', `Toast__${singleToast.type}`)}
			onMouseEnter={startPause}
			onMouseLeave={endPause}
		>
			<div
				className={clsx(
					'Toast__container',
					singleToast.visible ? 'Toast__container--in' : 'Toast__container--out',
				)}
			>
				<div
					key={singleToast.id}
					className={clsx('Toast__bar')}>
					<div className="Toast__message">
						{singleToast.type === 'quick-tips' ? (
							<div className="Toast__quick-tips-container">
								<div className="Toast__quick-tips-icon-container">
									<div className="Toast__quick-tips-icon">
										<FontAwesomeIcon icon={faLightbulbOn} />
									</div>
									{localization.Global.Toast.quickTips}
								</div>
								{singleToast.message}
							</div>
						) : (
							singleToast.message
						)}
					</div>
					<div
						className="Toast__close touchable-opacity"
						onClick={() => toastFunc.dismiss(singleToast.id)}
					>
						<FontAwesomeIcon icon={faTimes} />
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default Toast;
