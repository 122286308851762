import cloudinaryCore from 'cloudinary-core';
import isURL from 'validator/es/lib/isURL';
import url from 'url';

import {store} from 'store';
import {setOpenState} from 'store/actions/order';
import {setActiveCategoryIndex} from 'store/actions/menu';

import localization from 'localization';

import history from 'utils/history';

export const isTouchDevice = () => {
	return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
};

export const isIos = () => {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
};

export const removeNonASCII = value =>
	value && value.replace(/[^\x00-\x7F]+/g, ''); //eslint-disable-line no-control-regex

export const numberFormat = number => {
	return new Intl.NumberFormat('id-ID').format(number);
};

export const moneyPrefix = number => {
	return 'Rp' + numberFormat(number);
};

export const shortenRupiah = money => {
	const shortedMoney = money / 1000;
	if (shortedMoney >= 1) {
		return `${shortedMoney}rb`;
	} else {
		return money;
	}
};

export const cloudinaryImage = (cloudinaryUrl = '', config = {}) => {
	try {
		const isCloudinaryUrl = isURL(cloudinaryUrl, {
			require_protocol: false,
			host_whitelist: ['res.cloudinary.com'],
		});
		if (isCloudinaryUrl) {
			const {pathname} = url.parse(cloudinaryUrl);
			const imageId = pathname.slice(1).split('/').slice(4).join('/');
			const imageVersion = pathname.slice(1).split('/')[3].slice(1);
			const cl = cloudinaryCore.Cloudinary.new({
				cloud_name: 'dgsgylfvr',
			}).init();
			return cl.url(imageId, {...config, version: imageVersion});
		} else {
			return cloudinaryUrl;
		}
	} catch (error) {
		// Fallback by returning original url if the given url is not a valid cloudinary url
		return cloudinaryUrl;
	}
};

export const getCartItemsPayload = () => {
	const {items} = store.getState().cart;
	return items.map(item => ({
		id: item.menuId,
		esbMenuId: item.esbMenuId,
		qty: item.qty,
		notes: item?.takeawayToggled
			? item?.notes
				? item.notes + ' · ' + localization.Cart.takeaway
				: localization.Cart.takeaway
			: item.notes,
		submenus: item.submenus.map(submenu => ({
			id: submenu.id,
			qty: submenu.qty,
			esbMenuId: submenu.esbMenuId,
			groupId: submenu.groupId,
			subcategoryId: submenu.subcategoryId,
		})),
	}));
};

export const ESBDataExists = () => {
	const {companyCode, outletCode, transId} =
		store.getState().order.ESBData || {};
	if (companyCode && outletCode && transId) {
		return true;
	} else {
		history.replace('/invalid');
	}
};

export const redirectToReceiptIfClosed = () => {
	const {orderData} = store.getState().order;
	if (orderData.isBillClosed) {
		history.push('/receipt');
	}
};

export const openDeeplinkUrl = target => {
	if (target.includes(window.location.origin)) {
		const newUrl = new URL(target);
		if (newUrl.pathname === '/menu/browse') {
			// url params is always string thus category with index '0' is truthy
			const categoryIndex = Number(newUrl.searchParams.get('category') || -1);
			categoryIndex > -1 && store.dispatch(setActiveCategoryIndex(categoryIndex));
		}
		history.push(newUrl.pathname);
	} else {
		window.open(target, '_blank');
	}
};

export const setOutletOpenHours = (openHours = {}) => {
	// open hours
	const closeHour = openHours?.display?.split('- ')[1]?.slice(0, 5)?.split(':');
	let closeTime;
	if (closeHour) {
		const date = new Date();
		closeHour && date.setHours(closeHour[0], closeHour[1], 0);
		closeTime =
			// if the close time have passed, add one day to handle case where the outlet might open till 2 am next day (ex: curTime 23, closeTime: 2 am)
			// if the outlet is truly closed (ex: curTime 23, closeTime 22), the isOpen will be false
			date.getTime() < Date.now()
				? date.getTime() + 24 * 60 * 60 * 1000
				: date.getTime();
	}
	store.dispatch(
		setOpenState({
			closeTime,
			isOpen: openHours?.open,
		}),
	);
};

export const convertDeepObjectToString = data => {
	try {
		const newData = {};
		Object.keys(data).forEach(key => {
			newData[key] =
				typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
		});
		return newData;
	} catch (error) {
		return data;
	}
};

export const calculateGrantedPoint = (totalAmount, pointsMultiplier = 1) => {
	return Math.floor(totalAmount / 100) * pointsMultiplier;
};

export const checkMenuTags = (tags = []) => {
	const isAyceMenu = tags?.includes('ayce');
	const isVegetarian = tags?.includes('Vege');
	const isAlcohol = tags?.includes('Alcohol');
	const canTakeaway = tags?.includes('Takeaway');
	const isFavorite = tags?.includes('Fav');
	const hotLevel = tags?.find(e =>
		['hot', 'extra hot']?.includes(e?.toLowerCase()),
	);
	return {isAyceMenu, isVegetarian, isAlcohol, canTakeaway, isFavorite, hotLevel};
};
