import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import COLORS from 'assets/styles/_colors.module.scss';

import './style.scss';
import Button from 'components/Button';

function LargeCaption(props) {
	return (
		<div className={clsx('LargeCaption', props.className)}>
			<div className="LargeCaption__icon">
				{props.isSVG ? (
					<props.icon color={props.iconColor} />
				) : (
					<FontAwesomeIcon
						icon={props.icon}
						color={props.iconColor} />
				)}
			</div>
			<div className="LargeCaption__title">{props.title}</div>
			<div className="LargeCaption__description">{props.description}</div>
			{props.buttonText && (
				<Button
					className="LargeCaption__button"
					onClick={props.onClickButton}>
					{props.buttonText}
				</Button>
			)}
		</div>
	);
}

LargeCaption.defaultProps = {
	iconColor: COLORS.secondary,
	title: '',
	description: '',
	buttonText: '',
	isSVG: false,
	onClickButton: () => null,
};

LargeCaption.propTypes = {
	icon: PropTypes.object,
	iconColor: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	buttonText: PropTypes.string,
	isSVG: PropTypes.bool,
	onClickButton: PropTypes.func,
	className: PropTypes.string,
};

export default LargeCaption;
