import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './style.scss';

function Spinner(props) {
	switch (props.type) {
	case 'planetary':
		return (
			<React.Fragment>
				<div
					className={clsx('PlanetaryLoading', props.className)}
					style={{
						width: props.size,
						height: props.size,
						...props.style,
					}}
				>
					<div className="blue-orbit leo" />

					<div className="green-orbit leo" />

					<div className="red-orbit leo" />

					<div className="white-orbit w1 leo" />
					<div className="white-orbit w2 leo" />
					<div className="white-orbit w3 leo" />
				</div>
				<div className="loading-text">Loading</div>
			</React.Fragment>
		);
	case 'spinner':
		return (
			<div
				className={clsx('SpinLoading', props.className)}
				style={{
					width: props.size,
					height: props.size,
					borderTopColor: props.color,
					...props.style,
				}}
			/>
		);
	case 'pulse':
	default:
		return (
			<div
				className={clsx('PulseLoading', props.className)}
				style={props.style}
			>
				{[1, 2, 3].map(i => (
					<div
						key={i}
						style={{
							width: props.size,
							height: props.size,
							backgroundColor: props.color,
						}}
					/>
				))}
			</div>
		);
	}
}

Spinner.defaultProps = {
	type: 'pulse',
	color: 'black',
	size: 12,
};

Spinner.propTypes = {
	type: PropTypes.oneOf(['pulse', 'spinner', 'planetary']),
	color: PropTypes.string,
	size: PropTypes.number,
	className: PropTypes.string,
	style: PropTypes.object,
};

export default Spinner;
