import {ACTIONS} from '../actions/actionKeys';

const initialState = {
	categoryIndex: 0,
	scrollTop: 0,
	categories: [],
};

export default function menu(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_MENU_CATEGORIES:
		return {
			...state,
			categories: payload,
		};
	case ACTIONS.SET_ACTIVE_CATEGORY_INDEX:
		return {
			...state,
			categoryIndex: payload,
		};
	case ACTIONS.SET_LAST_MENU_SCROLL_TOP:
		return {
			...state,
			scrollTop: payload,
		};
	default:
		return state;
	}
}
