import {ACTIONS} from './actionKeys';

export const setESBData = payload => ({
	type: ACTIONS.SET_ESB_DATA,
	payload,
});

export const clearESBData = payload => ({
	type: ACTIONS.CLEAR_ESB_DATA,
	payload,
});

export const setTableNumber = payload => ({
	type: ACTIONS.SET_TABLE_NUMBER,
	payload,
});

export const setOrderData = payload => ({
	type: ACTIONS.SET_ORDER_DATA,
	payload,
});

export const setBillIsRated = payload => ({
	type: ACTIONS.SET_BILL_IS_RATED,
	payload,
});

export const setOpenState = payload => ({
	type: ACTIONS.SET_OPEN_STATE,
	payload,
});

export const setAyceData = payload => ({
	type: ACTIONS.SET_AYCE_DATA,
	payload,
});
export const clearAyceData = payload => ({
	type: ACTIONS.CLEAR_AYCE_DATA,
	payload,
});
